import React from 'react';
import './ConseilsCompo.css';

import DefaultImage from './../../assets/default-empty-image.svg';

const ConseilsCompo = ({ src, srcSet, label, id, onClick, alt, sticky }) => {
  return (
    <div className={`cardConseil ${sticky ? 'sticky' : ''}`} id={id} onClick={onClick}>
      <div className="thumbConseil">
        {src ? (
            <img src={src} srcSet={srcSet} alt={alt} />
        ) : (
            <img src={DefaultImage}/>
        )}
      </div>
      <h2 className="titleConseil">{label}</h2>
    </div>
  );
};

export default ConseilsCompo;
