import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

const PublicRoutes = () => {
  const isLogin = useSelector((state) => state.user.token);

  return isLogin ? <Navigate to='/accueil' /> : <Outlet />;
};

export default PublicRoutes;
