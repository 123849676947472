import React from 'react';

import EmptyImage from './../assets/default-empty-image.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faArrowDownToLine, faArrowUpRight, faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

library.add(faTrash, faArrowDownToLine, faXmark, faArrowUpRight, faQuoteLeft);

const SuiviModal = ({ closeModal, source, date, figure, commentaireFlexifit, onDelete, onDownload, isDeleteState, hasDeleteError, isDownloadState, hasDownloadError }) => {

  const handleInnerClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className='modalShowProgression' onClick={closeModal}>
      <div className='modalInnerShowProgression no-scrollbar' onClick={handleInnerClick}>
        <button className='closeModalProgression' onClick={closeModal}>
          <FontAwesomeIcon icon='fa-regular fa-xmark' />
        </button>
        <h3>Ma progression</h3>
        <p className="figure">{figure}</p>
        <p className='date'>{date}</p>

        {source ? (
          <img className='montageProgression' src={source} alt={`Progression du ${date} - ${figure}`}/>
        ) : (
          <img className="montageProgression" src={EmptyImage} alt="Montage par défaut"/>
        )}

        {source ? (
          <div className="actions">

            {commentaireFlexifit && (
              <div className="commentaireFlexifit"><p><FontAwesomeIcon icon='fa-solid fa-quote-left' />{commentaireFlexifit}</p></div>
            )}

            {!isDownloadState ? ( 
              <button className='btn icon' onClick={onDownload}>
                <FontAwesomeIcon icon='fa-solid fa-arrow-down-to-line' />
                Télécharger
              </button>
            ) : (
              <button className='btn'>
                <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
              </button>
            )}

            {!isDeleteState ? (
              <button className='btn btn-white delete icon' onClick={onDelete}>
                <FontAwesomeIcon icon='fa-solid fa-trash' />
                Supprimer
              </button>
            ) : (
              <button className='btn btn-white delete'>
                <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
              </button>
            )}

            {hasDownloadError && (
              <p className="alert">Il semblerait qu'il y ai eu une erreur durant le téléchargement de votre progression. Merci de réessayer ultérieurement ou de contacter notre service client.</p>
            )}
            {hasDeleteError && (
              <p className="alert">Il semblerait qu'il y ai eu une erreur durant la suppression de votre progression. Merci de réessayer ultérieurement ou de contacter notre service client.</p>
            )}

          </div>
        ) : (
          <div className="actions">

            {commentaireFlexifit && (
              <div className="commentaireFlexifit"><p><FontAwesomeIcon icon='fa-solid fa-quote-left' />{commentaireFlexifit}</p></div>
            )}

            <p className="alert">Il semblerait qu'il y ai une erreur sur votre progression. Contactez notre service client pour en savoir plus.</p>
            <a className='btn icon' target="_blank" rel="noreferrer" href="https://flexifit.app/contact/">
              <FontAwesomeIcon icon='fa-solid fa-arrow-up-right' />
              Nous contacter
            </a>
          </div>
        )}
        
      </div>
    </div>
  );
};

export default SuiviModal;