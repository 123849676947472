import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// import { api } from '../api';
import { usersSlice } from '../store/userSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const userPersistConfig = {
  key: 'user',
  storage,
};

const persistedUserReducer = persistReducer(userPersistConfig, usersSlice.reducer);

export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    // [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
  // .concat(api.middleware),
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export { persistor };
