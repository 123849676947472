import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoutes = () => {
  const isLogin = useSelector((state) => state.user.token);

  return isLogin ? <Outlet /> : <Navigate to='/login' />;
};

export default PrivateRoutes;
