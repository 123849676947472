import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from './../../store/userSlice';

import ProgrammesCompo from '../../components/actions/ProgrammesCompo';
import ConseilsCompo from '../../components/actions/ConseilsCompo';

// IMPORT COMPONENTS 
import './Accueil.css';
import MontageImage from './../../assets/MontagePhotosFlexifit.png';
import DefaultImage from './../../assets/default-empty-image.svg';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faChevronLeft, faUser} from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlass, faBarsFilter, faChartSimple } from '@fortawesome/pro-regular-svg-icons';
import { useQuery } from '@apollo/client';
import { GET_DASHBORAD_QUERY } from '../../api';

library.add(faChevronLeft, faMagnifyingGlass, faBarsFilter, faChartSimple, faUser);

export default function Accueil() {
  const token = useSelector((state) => state.user.token);
  const { data: dashboardData, loading, error: errorToken } = useQuery(GET_DASHBORAD_QUERY, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const dispatch = useDispatch();

  // Vérifiez s'il y a une erreur dans la requête GraphQL
  if (errorToken || (errorToken && errorToken.networkError && errorToken.networkError.statusCode >= 400 && errorToken.networkError.statusCode < 500)) {
    console.log('disconnect');
    dispatch(logout()); // Déconnectez l'utilisateur
  }
 
  // Programme a la une
  const nameUser = dashboardData?.viewer?.firstName;
  const avancementUser = dashboardData?.viewer?.userDatas?.avancement;

  const titleProgrammeUneApp = dashboardData?.dashboard?.appDashboard?.titreProgrammeUneApp;
  const programmeUneApp = dashboardData?.dashboard?.appDashboard?.programmeUneApp?.nodes?.[0];
  const nbSeancesProgrammeUne = programmeUneApp?.seances?.planificationProgramme?.reduce(
    (acc, planification) =>
      acc +
      (planification?.synchProgrammesSeances
        ? planification?.synchProgrammesSeances?.nodes?.length
        : 0),
    0
  );

  // Fonction pour calculer le pourcentage de progression
  const calculateProgression = (totalSeances, completedSeances) => {
    if (totalSeances === 0) return 0;
    return (completedSeances / totalSeances) * 100;
  };

  
  const filteredData = avancementUser?.filter(item => item.programmeId === programmeUneApp?.databaseId.toString());
  const completedSeances = filteredData?.reduce((total, item) => total + item.seances?.length, 0);
  const avancementPerCent = Math.round(calculateProgression(nbSeancesProgrammeUne, completedSeances));

  // Slides a la une
  const slidesApp = dashboardData?.dashboard?.appDashboard?.slidesApp;

  // Conseils a la une
  const conseilsUneApp = dashboardData?.dashboard?.appDashboard?.conseilsUneApp?.nodes;

  const navigate = useNavigate();

  const handleProgrammeClick = (id) => navigate(`/entrainements/programme/${id}`);
  const handleConseilClick = (id) => {
    navigate(`/conseils/${id}`);
  };

  return (
    <div className='w-full'>

      <div className='topBloc background sticked content'>
        <div className='leftSide'>
          <h1>Hello {nameUser}</h1>
        </div>

        <div className='rightSide'>
          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          ><FontAwesomeIcon icon='fa-solid fa-user' /></div>
        </div>

        <div
          onClick={() => navigate('/entrainements', { state: { isModalOpen: true } })}
          className='flex-full mt-4 md:mt-5 searchForm'
        >
          <FontAwesomeIcon className='searchIcon' icon='fa-regular fa-magnifying-glass' />
          <p className='label'>Rechercher un programme ou une séance</p>
          <div className='searchInput' />
          <FontAwesomeIcon className='filterIcon' icon='fa-regular fa-bars-filter' />
        </div>
      </div>

      <div className="blurredPattern peach"></div>

      {loading ? (
        <div className='contentAccueil content'>
          <div className='loading'>
            <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
          </div>
        </div>
      ) : (
        <div className='contentAccueil content'>
          {programmeUneApp && (
            <div className='programmeUne'>
              <h2 className='titleUne'>{titleProgrammeUneApp}</h2>
              <ProgrammesCompo
                onClick={() => handleProgrammeClick(programmeUneApp?.id)}
                niveau={programmeUneApp?.niveaux?.edges?.[0]?.node?.name}
                niveauSlug={programmeUneApp?.niveaux?.edges?.[0]?.node?.slug}
                label={programmeUneApp?.title}
                nbSeance={nbSeancesProgrammeUne}
                description={programmeUneApp?.content}
                alt={programmeUneApp?.thumbnailsProgramme?.thumbnailProgrammePortrait?.node?.altText}
                src={programmeUneApp?.thumbnailsProgramme?.thumbnailProgrammePortrait?.node?.sourceUrl}
                srcSet={programmeUneApp?.thumbnailsProgramme?.thumbnailProgrammePortrait?.node?.srcSet}
                id={programmeUneApp?.id}
                avancement={avancementPerCent}
              />
            </div>
          )}

          <div className='slidesBlock'>
            {slidesApp?.map((slide, index) => (
              <div className='rowSlide' key={index}>
                <h2 className='titleUne'>{slide.titre}</h2>
                <div className='rowSlideInner no-scrollbar'>
                  {slide?.rubrique?.nodes.map((cardSlide, slideIndex) => {
                    const navigateFromCadSlide = (category, slugTerm) => {
                      navigate('/entrainements', {
                        state: { filterFromCardSlide: { [category]: slugTerm } },
                      });
                    };
                    return (
                      <div
                        onClick={() => navigateFromCadSlide(cardSlide.taxonomyName, cardSlide.slug)}
                        className='cardSlide'
                        key={slideIndex}
                      >
                        <div key={cardSlide.id}>
                          {cardSlide.termsConfig &&
                          cardSlide.termsConfig.imageUne &&
                          cardSlide.termsConfig.imageUne.node ? (
                            <img
                              src={cardSlide?.termsConfig?.imageUne?.node?.sourceUrl}
                              srcSet={cardSlide?.termsConfig?.imageUne?.node?.srcSet}
                              alt={cardSlide?.termsConfig?.imageUne?.node?.altText}
                            />
                          ) : (
                            <img src={DefaultImage} alt='Default' />
                          )}
                          <p>{cardSlide.name}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>

          <div className='bannerSuivi'>
            <div className='bannerSuiviInner'>
              <div className='imgSide'>
                <img src={MontageImage} alt='Montage Progression Suivi Flexifit' />
              </div>
              <div className='contentSide'>
                <h3>
                  <FontAwesomeIcon icon='fa-regular fa-chart-simple' />
                  Évaluez vos progrès
                </h3>
                <p>
                  Importez une photo de vous avant/après votre progression dans l’onglet “Évolution”.
                  Nous évaluerons vos progrès à l’aide de notre outil de mesure.
                </p>
                <button
                  className='btn'
                  onClick={() => {
                    navigate('/evolution');
                  }}
                >
                  Envoyer mes photos
                </button>
              </div>
            </div>
          </div>

          <div className='conseilsUne'>
            <h2 className='titleUne'>Conseils</h2>
            <div className='conseilsUneContent'>
              {conseilsUneApp?.map((conseil, i) => {
                const conseilPublished = conseil?.configConseils.publierApp;
                if (conseilPublished)
                  return (
                    <ConseilsCompo
                      id={conseil.id}
                      key={i}
                      onClick={() => handleConseilClick(conseil.id)}
                      label={conseil?.title}
                      src={conseil?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.sourceUrl}
                      srcSet={conseil?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.srcSet}
                      alt={conseil?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.altText}
                    />
                  );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
