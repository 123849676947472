// IMPORT REACT
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { logout } from './../../store/userSlice';
// IMPORT API & APOLLO
import { useQuery, useMutation } from '@apollo/client';
import { GET_PROGRAMME_QUERY, POST_FAVORITE_MUTATION, GET_FAVORITE_QUERY } from '../../api';

// IMPORT COMPONENTS
import './ProgrammeDetails.css';
import SeanceCompo from '../../components/actions/SeanceCompo';
import RequireCompo from '../../components/actions/RequireCompo';
import ReactPlayer from 'react-player';
import Vimeo from '@u-wave/react-vimeo';

import DefaultImage from './../../assets/default-empty-image.svg';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faChevronLeft, faHeart, faPlay, faUser } from '@fortawesome/pro-solid-svg-icons';

library.add(faChevronLeft, faHeart, faPlay, faUser);

const ProgrammeDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [favoriteButton, setFavoriteButton] = useState(false);
  const [errorMessageFavorite, setErrorMessageFavorite] = useState(
    'erreur si le like ne fonctionne pas (decommenter le useEffect une fois le style fait)'
  );
  const { token, id: idUser } = useSelector((state) => state.user);
  const { data: favoriteData } = useQuery(GET_FAVORITE_QUERY, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const favoriteProgrammes = favoriteData?.viewer?.userDatas?.favoris?.nodes;
  const getIdFavorite = favoriteProgrammes?.map((programme) => programme.id);
  const isidParamsFavorite = getIdFavorite?.includes(id);

  const {
    data,
    loading,
    error: errorToken,
    refetch,
  } = useQuery(GET_PROGRAMME_QUERY, {
    variables: { programmeId: id },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  useEffect(() => {
    if (id) refetch();
  }, [id, refetch]);

  const dispatch = useDispatch();

  // Vérifiez s'il y a une erreur dans la requête GraphQL
  if (
    errorToken ||
    (errorToken &&
      errorToken.networkError &&
      errorToken.networkError.statusCode >= 400 &&
      errorToken.networkError.statusCode < 500)
  ) {
    console.log('disconnect');
    dispatch(logout()); // Déconnectez l'utilisateur
  }

  const [postFavorite, { loading: favoriteLoading }] = useMutation(POST_FAVORITE_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const programme = data?.programme;
  const databaseIdProgramme = programme?.databaseId;
  const thumbnailMobile = programme?.thumbnailsProgramme?.thumbnailProgrammeDetail?.node;
  const thumbnailDesktop = programme?.thumbnailsProgramme?.thumbnailProgrammeDetailDesktop?.node;
  const programmeTitle = programme?.title;
  const programmeThumbnailMobileSrc = thumbnailMobile?.sourceUrl;
  const programmeThumbnailMobileSrcSet = thumbnailMobile?.srcSet;
  const programmeThumbnailMobileAlt = thumbnailMobile?.altText;
  const programmeThumbnailDesktopSrc = thumbnailDesktop?.sourceUrl;
  const programmeThumbnailDesktopSrcSet = thumbnailDesktop?.srcSet;
  const programmeThumbnailDesktopAlt = thumbnailDesktop?.altText;
  const programmeNiveau = programme?.niveaux?.edges[0]?.node?.name;
  const programmeDescription = programme?.content;

  const introVideo = programme?.datasProgrammes?.introductionProgramme;

  const LienConseil = programme?.lienConseilProgrammes?.lienConseil?.nodes[0];
  const idLienConseil = LienConseil?.id;
  const conseilPublished = LienConseil?.configConseils.publierApp;

  const handleConseilClick = (id) => {
    navigate(`/conseils/${id}/`);
  };

  const objectifs = programme?.objectifs?.edges;
  const materiels = programme?.materiels?.edges;
  const durees = programme?.durees?.edges;

  const allSeances = data?.programme?.seances?.planificationProgramme;

  // Const access user avancement
  const avancementUser = data?.viewer?.userDatas?.avancement;

  const semaines = allSeances?.map((semaine, index) => {
    const eachSeance = semaine?.synchProgrammesSeances?.nodes.map((seance, index) => {
      const idSeance = seance.databaseId;
      const isSeanceInArray = avancementUser?.some((item) => {
        // Vérification si programmeId est présent dans le tableau avancement
        if (item.programmeId === databaseIdProgramme.toString()) {
          // Vérification si la seanceId est présente dans le tableau de séances du programme
          return item.seances?.some((seance) => seance.seanceId === idSeance.toString());
        }
        return false;
      });

      return (
        <SeanceCompo
          onClick={() => handleSeanceClick(seance.id)}
          key={index}
          id={seance.id}
          label={seance.title}
          src={seance?.thumbnailSeance?.thumbnailSeanceDetail?.node.sourceUrl}
          srcSet={seance?.thumbnailSeance?.thumbnailSeanceDetail?.node.srcSet}
          alt={seance?.thumbnailSeance?.thumbnailSeanceDetail?.node.altText}
          duree={seance?.datasSeances?.dureeSeance}
          viewed={isSeanceInArray}
        />
      );
    });

    return (
      <div className='slot' key={index}>
        <p className='label'>{semaine.titre}</p>
        {eachSeance}
      </div>
    );
  });

  // Nombre de seances
  const totalSeances = allSeances?.reduce((acc, semaine) => {
    const seancesCount = semaine?.synchProgrammesSeances?.nodes?.length || 0;
    return acc + seancesCount;
  }, 0);

  //const isIntroVideo = data?.programme?.datasProgrammes?.introductionProgramme;

  const handleSeanceClick = (id) => {
    navigate(`/entrainements/seance/${id}/`, { state: { dbProgramme: { databaseIdProgramme } } });
  };

  useEffect(() => {
    if (isidParamsFavorite) {
      setFavoriteButton(true);
    }
  }, [isidParamsFavorite]);

  const handleFavorite = async () => {
    const formattedId = databaseIdProgramme.toString();
    if (favoriteLoading) {
      return;
    }
    setFavoriteButton(!favoriteButton);
    try {
      const response = await postFavorite({
        variables: {
          id: idUser,
          updateFavoris: formattedId,
        },
      });
    } catch (err) {
      setFavoriteButton(!favoriteButton);
      setErrorMessageFavorite('Erreur lors du like');
      console.log(err);
    }
  };

  useEffect(() => {
    setErrorMessageFavorite(null);
  }, [favoriteButton, handleFavorite]);

  if (loading)
    return (
      <div className='w-full loadingScreen'>
        <div className='loading'>
          <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
        </div>
      </div>
    );

  return (
    <div className='w-full relative'>
      <div className='topBloc overlay content'>
        <div className='leftSide'>
          <div className='return'>
            <FontAwesomeIcon
              icon='fa-solid fa-chevron-left'
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>

        <div className='rightSide'>
          <div
            className={`IconFavoris ${favoriteButton ? 'active' : ''}`}
            onClick={() => handleFavorite()}
          >
            {favoriteButton ? (
              <FontAwesomeIcon icon='fa-solid fa-heart' />
            ) : (
              <FontAwesomeIcon icon='fa-regular fa-heart' />
            )}
          </div>

          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-user' />
          </div>
        </div>
      </div>

      <div className='blurredPattern brown detail'></div>

      {/* image header */}
      <div className='topThumbProgramme Desktop'>
        {programmeThumbnailDesktopSrc ? (
          <img
            src={programmeThumbnailDesktopSrc}
            srcSet={programmeThumbnailDesktopSrcSet}
            alt={programmeThumbnailDesktopAlt}
          />
        ) : (
          <img src={DefaultImage} alt='Thumnbail par défaut' />
        )}
      </div>

      <div className='topThumbProgramme Mobile'>
        {programmeThumbnailMobileSrc ? (
          <img
            src={programmeThumbnailMobileSrc}
            srcSet={programmeThumbnailMobileSrcSet}
            alt={programmeThumbnailMobileAlt}
          />
        ) : (
          <img src={DefaultImage} alt='Thumnbail par défaut' />
        )}
      </div>

      <div className='contentProgramme content'>
        {introVideo && (
          <div className='cta'>
            <a href='#intro'>
              <div className='play-icon'>
                <FontAwesomeIcon icon='fa-solid fa-play' />
              </div>
              <div className='action'>
                <p className='sublabel'>Découvrir</p>
                <p className='label'>Introduction</p>
              </div>
            </a>
          </div>
        )}

        {/* titre du programme */}
        <h1>{programmeTitle}</h1>

        <div className='infosProgramme'>
          {totalSeances && (
            <p className='seanceProgramme'>
              {totalSeances > 1 ? `${totalSeances} séances` : `${totalSeances} séance`}
            </p>
          )}
          {programmeNiveau && (
            <div className='levelProgramme'>
              <div className={`level ${programme?.niveaux?.edges[0]?.node?.slug}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <p>{programmeNiveau}</p>
            </div>
          )}
        </div>

        {errorMessageFavorite && <p className='alert'>{errorMessageFavorite}</p>}

        {semaines && (
          <a href='#composition' className='btn startProgramme'>
            Commencer le programme
          </a>
        )}

        {/* objectifs + materiels requis  */}

        <div className='requiredBlock'>
          {materiels?.length > 0 && (
            <RequireCompo
              label={materiels && 'Matériels'}
              tab={materiels ? materiels : []}
              getPath={(e) => e.node.name}
            />
          )}

          {objectifs?.length > 0 && (
            <RequireCompo
              label={objectifs && 'Objectifs'}
              tab={objectifs ? objectifs : []}
              getPath={(e) => e.node.name}
            />
          )}

          {durees?.length > 0 && (
            <RequireCompo
              label={durees && 'Durée des séances'}
              tab={durees ? durees : []}
              getPath={(e) => e.node.name}
            />
          )}
        </div>

        {programmeDescription && (
          <div
            className='descriptionProgramme blockTextRendered'
            dangerouslySetInnerHTML={{ __html: programmeDescription }}
          ></div>
        )}

        {LienConseil && conseilPublished && (
          <button onClick={() => handleConseilClick(idLienConseil)} className='btn btn-beige'>
            Découvrir des conseils
          </button>
        )}

        {/* Intro video */}
        {introVideo && (
          <div id='intro' className='player-wrapper aspect-video'>
            <h2 className='title'>Introduction</h2>
            {/* <ReactPlayer url={introVideo} controls={true} /> */}
            <Vimeo video={introVideo} controls={true} responsive={true} />
          </div>
        )}

        {/* Composition programme */}
        {semaines && <div id='composition'>{semaines}</div>}
      </div>
    </div>
  );
};

export default ProgrammeDetails;
