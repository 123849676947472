import React from 'react';
import './SeanceCompo.css';

import DefaultImage from './../../assets/default-empty-image.svg';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faCheck  } from '@fortawesome/pro-solid-svg-icons';

library.add(faCheck);

const SeanceCompo = ({ id, src, srcSet, label, duree, alt, viewed, onClick }) => {
  return (
    <div className={`rowSeance${viewed ? ' viewed' : ''}`} onClick={onClick} id={id}>
      <div className="thumbSeance">
        {src ? (
            <img src={src} srcSet={srcSet} alt={alt} />
        ) : (
            <img src={DefaultImage}/>
        )}
      </div>
      <div className="Seancecontent">
        <p className="titleSeance">{label}</p>
        <p className="dureeSeance">{duree}min.</p>
      </div>
      {viewed && (
          <div className='viewedNotif'>
            <div className="viewedNotifInner">
              <FontAwesomeIcon icon='fa-solid fa-check' />
            </div>
          </div>
        )}
    </div>
  );
};

export default SeanceCompo;