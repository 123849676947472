import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faCheck, faXmark, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
library.add(faCheck, faXmark, faEye, faEyeSlash);

const InputLogin = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  isPassword,
  isEmail,
  marginBottom,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(value));
  };

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    if (isEmail) {
      inputValue = inputValue.toLowerCase();
    }
    onChange(inputValue);
  };

  useEffect(() => {
    if (isEmail) {
      validateEmail();
    }
  }, [isEmail, value]);

  return (
    <div className='input-row'>
      <label>{label}</label>
      <div>
        <input
          type={isPassword ? (showPassword ? 'text' : 'password') : type}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
        />
        {isPassword && (
          <span className='validate showhide' type='button' onClick={togglePasswordVisibility}>
            {showPassword ? (
              <FontAwesomeIcon icon='fa-solid fa-eye' />
            ) : (
              <FontAwesomeIcon icon='fa-solid fa-eye-slash' />
            )}
          </span>
        )}
        {isEmail && (
          <div className='validate'>
            {isEmailValid ? (
              <FontAwesomeIcon className='text-green' icon='fa-solid fa-check' />
            ) : (
              <FontAwesomeIcon className='text-red' icon='fa-solid fa-xmark' />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputLogin;
