import React from 'react';

import EmptyImage from './../../assets/default-empty-image.svg';

const SuiviCompo = ({ source, date, figure, onClick }) => {
  return (
    <div className="pogressionCard" onClick={onClick}>
      {source ? (
        <img className="imgProgressionCard" src={source}  alt={`Progression du ${date} - ${figure}`}/>
      ) : (
        <img className="imgProgressionCard" src={EmptyImage} alt="Montage par défaut"/>
      )}
      <p className="figureProgressionCard">{figure}</p>
      <p className="dateProgressionCard">{date}</p>
    </div>
  );
};

export default SuiviCompo;
