const RequireCompo = ({ label, tab, getPath }) => {
  return (
    <div className="required">
      <p className="label">{label}</p>
      <div className="pills">
        {tab.map((element, index) => {
          return <span className="pill" key={index}>{getPath(element)}</span>;
        })}
      </div>
    </div>
  );
};

export default RequireCompo;
