import React, { useState } from 'react';

import './OnBoarding.css';

const OnBoarding = ({ steps, closeModal }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const goToNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const isLastStep = currentStep === steps.length - 1;

  return (
    <div className='boards'>
      <div className='img-side'>
        <img
          src={steps[currentStep]?.featuredImage?.node?.sourceUrl}
          srcSet={steps[currentStep]?.featuredImage?.node?.srcSet}
          alt={steps[currentStep]?.featuredImage?.node?.altText}
        />
      </div>
      <div className='content-side'>
        <div className='content-inner'>
          <h2 className='text-center mb-4'>{steps[currentStep]?.datasOnboardings?.titre}</h2>
          <p className='text-center text-gray'>{steps[currentStep]?.datasOnboardings?.contenu}</p>
          <div>
            {isLastStep ? (
              <button className='btn w-full mt-6' onClick={closeModal}>
                Commencer
              </button>
            ) : (
              <button className='btn w-full mt-6' onClick={goToNextStep} disabled={isLastStep}>
                Suivant
              </button>
            )}
            <div className='dotted mt-6 md:mt-12'>
              {steps.map((_, index) => (
                <div key={index} className={`dot ${index === currentStep ? 'active' : ''}`}></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnBoarding;
