import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { logout } from './../../store/userSlice';
import {
  GET_SUIVIS_QUERY,
  POST_PROGRESSION_MUTATION,
  GET_FIGURES_SUIVI,
  DELETE_PROGRESSION_MUTATION,
} from '../../api';

// IMPORT COMPONENTS
import './Suivi.css';
import SuiviModal from '../../components/SuiviModal';
import { convertDateISOToJJMMAAAA, todayDate } from '../../utils/stringsUtils';
import SuiviCompo from '../../components/actions/SuiviCompo';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faTrash, faUser, faBadgeCheck, faCircleExclamation, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

library.add(faTrash, faUser, faBadgeCheck, faCircleExclamation, faCircleInfo);

export default function Suivi() {
  const { databaseId, token } = useSelector((state) => state.user);
  const apolloContext = {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  };
  const { data, error: errorToken, refetch } = useQuery(GET_SUIVIS_QUERY, apolloContext);
  const { data: dataFigures } = useQuery(GET_FIGURES_SUIVI, apolloContext);
  const [postProgression, { loading }] = useMutation(POST_PROGRESSION_MUTATION, apolloContext);
  const [deleteProgression] = useMutation(DELETE_PROGRESSION_MUTATION, apolloContext);


  const dispatch = useDispatch();
  
  // Vérifiez s'il y a une erreur dans la requête GraphQL
  if (errorToken || (errorToken && errorToken.networkError && errorToken.networkError.statusCode >= 400 && errorToken.networkError.statusCode < 500)) {
    console.log('disconnect');
    dispatch(logout()); // Déconnectez l'utilisateur
  }

  const [avant, setAvant] = useState('');
  const [apres, setApres] = useState('');
  const [disposition, setDisposition] = useState(true);
  const [figure, setFigure] = useState(null);
  const [note, setNote] = useState('');
  const [usage, setUsage] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [figureVerif, setFigureVerif] = useState(false);

  const [filterFigure, setFilterFigure] = useState('');

  const navigate = useNavigate();

  const [selectedProgression, setSelectedProgression] = useState(null);

  const [specificLoading, setSpecificLoading] = useState(true);

  const convertToBase64 = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const img = new Image();
        img.src = fileReader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Calculer les nouvelles dimensions tout en conservant le ratio
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL('image/jpg'));
        };
        img.onerror = (error) => {
          reject(error);
        };
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleAvantChange = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertToBase64(file, 1500, 1500);
    setAvant(base64);
  };
  const handleApresChange = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertToBase64(file, 1500, 1500);
    setApres(base64);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmitProgression = async () => {
    if (!figure || figure === '') {
      setFigureVerif(true);
      return;
    }
    const userFormatted = databaseId;
    try {
      const response = await postProgression({
        variables: {
          title: todayDate(),
          avant,
          apres,
          disposition,
          figure: Number(figure),
          usage,
          note,
          utilisateur: userFormatted,
        },
      });
      if (response.data) {
        setSuccessMessage('Vos photos ont bien été envoyées, merci ! Notre équipe vous fera un retour d\'ici 48h.');
        setTimeout(() => {
          setSuccessMessage('');
        }, 4000);
        setAvant('');
        setApres('');
        setDisposition(true);
        setFigure('');
        setNote('');
        setUsage(false);
      }
      refetch();
    } catch (err) {
      console.log(err);
      setErrorMessage('Une erreur s\'est produite lors de l\'envoi de vos photos, veuillez réessayer ou contacter notre support.');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  const allFigures = dataFigures?.configurations?.appConfig?.figuresListProgressions?.nodes?.map((figure, index) => (
    <option key={index} value={figure.databaseId}>
      {figure.name}
    </option>
  ));

  let publishedProgression;
  const allProgression = data?.viewer?.userDatas?.progression?.nodes || [];
  if (filterFigure !== '') {
    publishedProgression = allProgression?.filter(
      (progression) =>
        progression?.figures?.nodes[0]?.databaseId === parseInt(filterFigure) &&
        progression.status === 'publish'
    );
  } else {
    publishedProgression = allProgression?.filter(
      (progression) => progression.status === 'publish'
    );
  }


  const [deleteLoading, setDeleteLoading] = useState('');
  const [hasDeleteError, setDeleteError] = useState('');

  const handleDeleteProgression = async (id) => {
    try {
      setDeleteError(false);
      setDownloadError(false);
      setDeleteLoading(true);
      const response = await deleteProgression({
        variables: {
          id,
        },
      });
      setSelectedProgression(null);
      setOpenModal(false);
      refetch();
      setDeleteLoading(false);
    } catch (err) {
      console.log(err);
      setDeleteLoading(false);
      setDeleteError(true);
    }
  };


  const [downloadLoading, setDownloadLoading] = useState('');
  const [hasDownloadError, setDownloadError] = useState('');

  const handleDownload = async (imageUrl) => {
    try {
        setDeleteError(false);
        setDownloadError(false);
        setDownloadLoading(true);

        // Fetch the image through the proxy server
        const response = await fetch(`/api/proxy?url=${encodeURIComponent(imageUrl)}`, {
          headers: {
            'Accept': 'image/png'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch image');
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);

        setDownloadLoading(false);
    } catch (err) {
        console.log(err);
        setDownloadLoading(false);
        setDownloadError(true);
    }
  };

  useEffect(() => {
    setFigureVerif(false);
  }, [figure]);

  useEffect(() => {
    if (data) {
      setSpecificLoading(false);
    }
  }, [data]);

  return (
    <div className='w-full'>
      <div className='topBloc background sticked content'>
        <div className='leftSide'>
          <h1>Évolution</h1>
        </div>

        <div className='rightSide'>
          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          ><FontAwesomeIcon icon='fa-solid fa-user' /></div>
        </div>
      </div>

      <div className="blurredPattern perle"></div>

      <div className='contentSuivi content'>
        <div className='chapoPage'>
          <h2>Créer un avant/après</h2>
          <p>
            Déposer une photo avec un point de départ et une autre photo avec un point à évaluer.
            Notre équipe génèrera votre avant/après.
          </p>
        </div>

        <div className='how-to'>
            <div className='alert informations info-message'>
              <a href="https://app.flexifit.app/conseils/cG9zdDoxNTA3">
                <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                <span>Retrouvez ici quelques conseils pour bien prendre vos photos et que nous puissions calculer votre progression !</span>
              </a>
            </div>
        </div>

        <div className={'canvasProgression ' + (disposition ? 'portrait' : 'paysage')}>
          <div className='canvasBeforeProgression'>
            {!avant && (
              <div className='canvasActions'>
                <p className='label'>Avant</p>
                <div className='chooseImg'>
                  <button className='btn-sm btn'>Choisir un fichier</button>
                  <input type='file' accept='image/*' onChange={handleAvantChange} />
                </div>
              </div>
            )}

            {avant && <img src={avant} alt='Thumnbail progression avant' />}

            {avant && (
              <button className='deleteImg' onClick={() => setAvant('')}>
                <FontAwesomeIcon icon='fa-solid fa-trash' />
              </button>
            )}
          </div>

          <div className='canvasSep'></div>

          <div className='canvasAfterProgression'>
            {!apres && (
              <div className='canvasActions'>
                <p className='label'>Après</p>
                <div className='chooseImg'>
                  <button className='btn-sm btn'>Choisir un fichier</button>
                  <input type='file' accept='image/*' onChange={handleApresChange} />
                </div>
              </div>
            )}

            {apres && <img src={apres} alt='Thumnbail progression après' />}

            {apres && (
              <button className='deleteImg' onClick={() => setApres('')}>
                <FontAwesomeIcon icon='fa-solid fa-trash' />
              </button>
            )}
          </div>
        </div>

        <div className='switcherDispo'>
          <input
            type='checkbox'
            id='switch'
            checked={disposition}
            onChange={() => setDisposition(!disposition)}
          />
          <label for='switch'>
            <div className='toggle'></div>
            <div className='names'>
              <p className='portrait'>Portrait</p>
              <p className='paysage'>Paysage</p>
            </div>
          </label>
        </div>

        {!loading && apres && avant && (
          <div className='validateBlock'>

            <div>
              <select className='chooseFigures' onChange={(e) => setFigure(e.target.value)}>
                <option value=''>Choisir une figure</option>
                {allFigures}
              </select>
              {figureVerif && <p className='alert'>Veuillez sélectionner une figure</p>}
            </div>

            <div className='commentaire'>
              <label>
                Commentaire<span>(facultatif)</span>
                <textarea name="commentaire" onChange={handleNoteChange} value={note} />
              </label>
            </div>

            <button disabled={loading} className='btn' onClick={handleSubmitProgression}>
              Envoyer mon Avant/Après
            </button>

            <p className='autorization'>
              En validant votre avant/après, vous autorisez Flexifit à utiliser vos images pour
              concevoir un montage de avant/après.
            </p>

            <div className='autorize'>
              <label>
                <span></span>
                J’autorise Flexifit à utiliser ma progression à des fins marketing sur le site et
                les réseaux sociaux de l’application
                <input type='checkbox' checked={usage} onChange={() => setUsage(!usage)} />
              </label>
            </div>
          </div>
        )}

        {loading && (
          <div className='loading'>
            <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
            <span>Envoi en cours</span>
          </div>
        )}

        {errorMessage && (
          <div className='alert error-message'>
            <FontAwesomeIcon icon='fa-solid fa-circle-exclamation' />
            <span>{errorMessage}</span>
          </div>
        )}

        {successMessage && (
          <div className='alert validate success-message'>
            <FontAwesomeIcon icon='fa-solid fa-badge-check' />
            <span>{successMessage}</span>
          </div>
        )}

        <div className='userProgressions'>
          <div className='topUserProgressions'>
            <h2>Mes progressions</h2>

            <select className='chooseFigures' onChange={(e) => setFilterFigure(e.target.value)}>
              <option value=''>Filtrer - Toutes figures</option>
              {allFigures}
            </select>
          </div>

          {specificLoading ? (
            <div className='galleryUserProgressions loading'>
              <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
            </div>
          ) : publishedProgression.length > 0 ? (
            <div className='galleryUserProgressions'>
              {publishedProgression.map((progression, index) => {
                const formattedDate = convertDateISOToJJMMAAAA(progression?.date);
                return (
                  <SuiviCompo
                    key={index}
                    figure={progression?.figures?.nodes[0]?.name}
                    date={formattedDate}
                    source={progression?.progressionDatas?.montageProgression?.node.sourceUrl}
                    onClick={() => {
                      setOpenModal(true);
                      setSelectedProgression(progression);
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <div className='galleryUserProgressions noResult'>
              <p>Vous n'avez pas encore de progression</p>
            </div>
          )}

        </div>
      </div>

      {openModal && (
        <SuiviModal
          closeModal={() => setOpenModal(false)}
          onDownload={() => handleDownload(selectedProgression?.progressionDatas?.montageProgression?.node.sourceUrl)}
          isDownloadState= {downloadLoading}
          hasDownloadError = {hasDownloadError}
          figure={selectedProgression?.figures?.nodes[0]?.name}
          source={selectedProgression?.progressionDatas?.montageProgression?.node.sourceUrl}
          commentaireFlexifit={selectedProgression?.progressionDatas?.commentaireProgression}
          date={convertDateISOToJJMMAAAA(selectedProgression?.date)}
          onDelete={() => handleDeleteProgression(selectedProgression?.databaseId)}
          isDeleteState= {deleteLoading}
          hasDeleteError = {hasDeleteError}
        />
      )}
    </div>
  );
}
