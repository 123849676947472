// IMPORT REACT
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { logout } from './../../store/userSlice';
// IMPORT API & APOLLO
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_SEANCE_QUERY,
  POST_FAVORITE_MUTATION,
  GET_FAVORITE_QUERY,
  POST_UPDATE_AVANCEMENT_MUTATION,
} from '../../api';
// IMPORT COMPONENTS
import './Seance.css';
import ReactPlayer from 'react-player';
import RequireCompo from '../../components/actions/RequireCompo';

import DefaultImage from './../../assets/default-empty-image.svg';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faChevronLeft,
  faHeart,
  faPlay,
  faFire,
  faSpa,
  faUser,
  faCheck,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import Vimeo from '@u-wave/react-vimeo';

library.add(faChevronLeft, faHeart, faPlay, faFire, faSpa, faUser, faCheck, faPlus);

const Seance = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { seanceId, dbIdProgramme } = useParams();
  const { token, id: userId, databaseId } = useSelector((state) => state.user);
  const [favoriteButton, setFavoriteButton] = useState(false);

  //const [triggerHandlePlayerVideo, setTriggerHandlePlayerVideo] = useState(false);
  const [seanceTerminated, setSeanceTerminated] = useState(false);
  const [isSeanceInAvancement, setIsSeanceInAvancement] = useState(false);

  const [errorMessageFavorite, setErrorMessageFavorite] = useState(
    'erreur si le like ne fonctionne pas (decommenter le useEffect une fois le style fait)'
  );

  const { data: favoriteData, loading: favoriteLoading } = useQuery(GET_FAVORITE_QUERY, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const favoriteProgrammes = favoriteData?.viewer?.userDatas?.favoris?.nodes;
  const getIdFavorite = favoriteProgrammes?.map((seance) => seance.id);
  const isidParamsFavorite = getIdFavorite?.includes(seanceId);

  const {
    data,
    loading,
    error: errorToken,
  } = useQuery(GET_SEANCE_QUERY, {
    variables: { id: seanceId },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const dispatch = useDispatch();

  // Vérifiez s'il y a une erreur dans la requête GraphQL
  if (
    errorToken ||
    (errorToken &&
      errorToken.networkError &&
      errorToken.networkError.statusCode >= 400 &&
      errorToken.networkError.statusCode < 500)
  ) {
    console.log('disconnect');
    dispatch(logout()); // Déconnectez l'utilisateur
  }

  const [postFavorite, { loading: postLoadingFavorite }] = useMutation(POST_FAVORITE_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  // Condition si provenance de programme
  const location = useLocation();
  const hasIdProgrammeState = location?.state?.dbProgramme;
  const dbIdProgrammeState = hasIdProgrammeState?.databaseIdProgramme.toString();
  const seanceDbId = data?.seance?.databaseId.toString();

  const [postUpdateSeance, { data: responseUpdaAvancement, error }] = useMutation(
    POST_UPDATE_AVANCEMENT_MUTATION,
    {
      variables: {
        id: userId,
        programmeId: dbIdProgrammeState,
        seanceId: seanceDbId,
      },
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }
  );

  const seance = data?.seance;
  const databaseIdSeance = seance?.databaseId;
  const thumbnailDesktop = seance?.thumbnailSeance?.thumbnailSeanceDetailDesktop?.node;
  const seanceThumbnailDesktopSrc = thumbnailDesktop?.sourceUrl;
  const seanceThumbnailDesktopSrcSet = thumbnailDesktop?.srcSet;
  const seanceThumbnailDesktopAlt = thumbnailDesktop?.altText;
  const thumbnailMobile = seance?.thumbnailSeance?.thumbnailSeanceDetail?.node;
  const seanceThumbnailMobileSrc = thumbnailMobile?.sourceUrl;
  const seanceThumbnailMobileSrcSet = thumbnailMobile?.srcSet;
  const seanceThumbnailMobileAlt = thumbnailMobile?.altText;
  const seanceTitle = seance?.title;

  const seanceDuree = seance?.datasSeances?.dureeSeance;

  const seanceLabel = seance?.datasSeances?.labelSeance;
  const seanceUrlVideo = seance?.datasSeances?.urlSeance;
  const seanceLabel2 = seance?.datasSeances?.labelSeance2;
  const seanceUrlVideo2 = seance?.datasSeances?.urlSeance2;

  const materiels = seance?.materiels?.edges;
  const level = seance?.niveaux?.nodes[0]?.name;
  const intensite = seance?.intensites?.nodes[0]?.name;
  const desc = seance?.content;

  const LienConseil = seance?.lienConseilSeances?.lienConseil?.nodes[0];
  const idLienConseil = LienConseil?.id;
  const conseilPublished = LienConseil?.configConseils.publierApp;

  const handleConseilClick = (id) => {
    navigate(`/conseils/${id}/`);
  };

  const isCoolDown = seance?.datasSeances?.enableCoolDown;
  const coolDownTitle = seance?.datasSeances?.coolDown?.titre;
  const coolDownDuree = seance?.datasSeances?.coolDown?.duree;
  const coolDownUrlVideo = seance?.datasSeances?.coolDown?.urlVideo;

  useEffect(() => {
    if (hasIdProgrammeState) {
      // Vérifier si la séance existe déjà dans avancement
      const seanceExistsInAvancement = data?.viewer?.userDatas?.avancement?.some(
        (programme) =>
          programme.programmeId === dbIdProgrammeState.toString() &&
          programme.seances?.some((seance) => seance.seanceId === seanceDbId.toString())
      );
      setIsSeanceInAvancement(!!seanceExistsInAvancement);
    }
  }, [data, dbIdProgrammeState, seanceDbId]);

  const handleButtonClick = async () => {
    try {
      await postUpdateSeance();

      // Si la séance est dans avancement, la supprimer
      if (isSeanceInAvancement) {
        setIsSeanceInAvancement(false);
        //console.log('Suppression');
      } else {
        setIsSeanceInAvancement(true);
        //console.log('Ajout');
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de la mise à jour de la séance:", error);
    }

    setSeanceTerminated(!seanceTerminated);
    //setTriggerHandlePlayerVideo(true);
  };

  useEffect(() => {
    if (isidParamsFavorite) {
      setFavoriteButton(true);
    }
  }, [isidParamsFavorite]);

  const handleFavorite = async () => {
    const formattedId = databaseIdSeance.toString();
    if (postLoadingFavorite) return;
    setFavoriteButton(!favoriteButton);
    try {
      const response = await postFavorite({
        variables: {
          id: userId,
          updateFavoris: formattedId,
        },
      });
    } catch (err) {
      setFavoriteButton(!favoriteButton);
      setErrorMessageFavorite('Erreur lors du like');
      console.log(err);
    }
  };

  useEffect(() => {
    setErrorMessageFavorite(null);
  }, [favoriteButton, handleFavorite]);

  useEffect(() => {
    if (responseUpdaAvancement) {
      //console.log('responseUpdaAvancement', responseUpdaAvancement);
    }
    if (error) {
      console.log('error', error);
    }
  }, [responseUpdaAvancement, error]);

  if (loading) return;

  return (
    <div className='w-full relative'>
      <div className='topBloc overlay content'>
        <div className='leftSide'>
          <div className='return'>
            <FontAwesomeIcon
              icon='fa-solid fa-chevron-left'
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>

        <div className='rightSide'>
          <div className={`IconFavoris ${favoriteButton ? 'active' : ''}`} onClick={handleFavorite}>
            {favoriteButton ? (
              <FontAwesomeIcon icon='fa-solid fa-heart' />
            ) : (
              <FontAwesomeIcon icon='fa-regular fa-heart' />
            )}
          </div>
          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-user' />
          </div>
        </div>
      </div>

      <div className='blurredPattern brown detail'></div>

      {/* image header */}
      <div className='topThumbSeance Desktop'>
        {seanceThumbnailDesktopSrc ? (
          <img
            src={seanceThumbnailDesktopSrc}
            srcSet={seanceThumbnailDesktopSrcSet}
            alt={seanceThumbnailDesktopAlt}
          />
        ) : (
          <img src={DefaultImage} alt='Thumnbail par défaut' />
        )}
      </div>

      <div className='topThumbSeance Mobile'>
        {seanceThumbnailMobileSrc ? (
          <img
            src={seanceThumbnailMobileSrc}
            srcSet={seanceThumbnailMobileSrcSet}
            alt={seanceThumbnailMobileAlt}
          />
        ) : (
          <img src={DefaultImage} alt='Thumnbail par défaut' />
        )}
      </div>

      <div className='contentSeance content'>
        <div className='cta'>
          <a href='#seance'>
            <div className='play-icon'>
              <FontAwesomeIcon icon='fa-solid fa-play' />
            </div>
            <div className='action'>
              <p className='label'>Commencer le cours</p>
              {seanceDuree && <p className='sublabel'>{seanceDuree}min.</p>}
            </div>
          </a>
        </div>

        {/* titre de seance */}
        <h1>{seanceTitle}</h1>

        <div className='infosSeance'>
          {level && (
            <div className='levelSeance'>
              <div className={`level ${seance?.niveaux?.nodes[0].slug}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <p>{level}</p>
            </div>
          )}

          {intensite && intensite !== 'Récupération' ? (
            <div className='intensiteSeance'>
              <div className={`intensite ${seance?.intensites?.nodes[0].slug}`}>
                <FontAwesomeIcon icon='fa-solid fa-fire' />
                <FontAwesomeIcon icon='fa-solid fa-fire' />
                <FontAwesomeIcon icon='fa-solid fa-fire' />
              </div>
              <p>{intensite}</p>
            </div>
          ) : (
            <div className='intensiteSeance'>
              <div className={`intensite ${seance?.intensites?.nodes[0].slug}`}>
                <FontAwesomeIcon icon='fa-solid fa-spa' />
              </div>
              <p>{intensite}</p>
            </div>
          )}
        </div>

        {errorMessageFavorite && <p className='alert'>{errorMessageFavorite}</p>}

        {/* Materiels Requis */}
        <div className='requiredBlock'>
          {materiels?.length > 0 && (
            <RequireCompo
              label={materiels && 'Matériels'}
              tab={materiels ? materiels : []}
              getPath={(e) => e.node.name}
            />
          )}
        </div>

        {/* desc */}
        {desc && (
          <div
            className='descriptionSeance blockTextRendered'
            dangerouslySetInnerHTML={{ __html: desc }}
          ></div>
        )}

        {LienConseil && conseilPublished && (
          <button onClick={() => handleConseilClick(idLienConseil)} className='btn btn-beige'>
            Découvrir des conseils
          </button>
        )}

        {/* Vérifie si l'un des deux URL de séance existe */}
        {(seanceUrlVideo || seanceUrlVideo2) && (
          <div id='seance' className='player-wrapper aspect-video'>
            {seanceUrlVideo && (
              <>
                <h2 className='title'>
                  {seanceLabel ? seanceLabel : seanceUrlVideo2 ? 'Séance 1/2' : 'Séance'}
                </h2>
                {/* <ReactPlayer
                  url={seanceUrlVideo}
                  controls={true}
                  //onStart={hasIdProgrammeState ? handlePlayerVideo : undefined}
                /> */}
                <Vimeo responsive={true} video={seanceUrlVideo} controls={true} />
              </>
            )}

            {seanceUrlVideo2 && (
              <>
                <h2 className='title'>
                  {seanceLabel2 ? seanceLabel2 : seanceUrlVideo2 ? 'Séance 2/2' : ''}
                </h2>
                {/* <ReactPlayer
                  url={seanceUrlVideo2}
                  controls={true}
                  //onStart={hasIdProgrammeState ? handlePlayerVideo : undefined}
                /> */}
                <Vimeo responsive={true} video={seanceUrlVideo2} controls={true} />
              </>
            )}

            {/* Bouton pour déclencher handlePlayerVideo */}
            {hasIdProgrammeState && (
              <button
                onClick={handleButtonClick}
                className={
                  isSeanceInAvancement
                    ? 'btn btn-skin icon btnAvancement'
                    : 'btn btn-primary icon btnAvancement'
                }
              >
                <FontAwesomeIcon
                  icon={isSeanceInAvancement ? 'fa-solid fa-check' : 'fa-solid fa-plus'}
                />
                {isSeanceInAvancement ? 'Séance terminée' : 'Marquer comme terminée'}
              </button>
            )}
          </div>
        )}

        {/* Cool down si il y a */}
        {isCoolDown && coolDownUrlVideo && (
          <div className='coolDown'>
            <div className='topCoolDown'>
              <h2>{coolDownTitle}</h2>
              <p className='timeCoolDown'>{coolDownDuree}min</p>
            </div>
            <div className='coolDownVideo player-wrapper'>
              {/* <ReactPlayer url={coolDownUrlVideo} controls={true} /> */}
              <Vimeo responsive={true} video={coolDownUrlVideo} controls={true} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Seance;
