import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMediaQuery } from '@mui/material';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from '../store/userSlice';

import './Navigation.css';

// Imports icons
import IconAccueil from '../assets/icon-nav/icon-accueil.svg';
import IconProgrammes from '../assets/icon-nav/icon-programmes.svg';
import IconConseils from '../assets/icon-nav/icon-conseils.svg';
import IconSuivi from '../assets/icon-nav/icon-suivi.svg';
// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';

import { createTheme, ThemeProvider } from '@mui/material/styles';

library.add(faHeart, faUser);

const theme = createTheme({});

const Navigation = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const drawerWidth = 300;

  const isMobile = useMediaQuery('(max-width: 992px)');

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.startsWith('/accueil')) {
      setValue(0);
    } else if (currentPath.startsWith('/entrainements')) {
      setValue(1);
    } else if (currentPath.startsWith('/conseils')) {
      setValue(2);
    } else if (currentPath.startsWith('/evolution')) {
      setValue(3);
    } else if (currentPath.startsWith('/profil')) {
      setValue(4);
    } else if (currentPath.startsWith('/favoris')) {
      setValue(5);
    } else {
      setValue(0);
    }
  }, [location.pathname]);

  const handleNavigation = (index) => {
    switch (index) {
      case 0:
        navigate('/accueil');
        break;
      case 1:
        navigate('/entrainements');
        break;
      case 2:
        navigate('/conseils');
        break;
      case 3:
        navigate('/evolution');
        break;
      case 4:
        navigate('/profil');
        break;
      case 5:
        navigate('/favoris');
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isMobile ? (
        <ThemeProvider theme={theme}>
          <BottomNavigation
            value={value}
            showLabels={true}
            onChange={(event, newValue) => {
              setValue(newValue);
              handleNavigation(newValue);
            }}
          >
            <BottomNavigationAction
              label='Accueil'
              disableRipple={true}
              icon={<img src={IconAccueil} alt='Icone accueil' style={{ width: 25, height: 25 }} />}
            />
            <BottomNavigationAction
              disableRipple={true}
              icon={
                <img
                  src={IconProgrammes}
                  alt='Icone Entraînements'
                  style={{ width: 25, height: 25 }}
                />
              }
              label='Entraînements'
            />
            <BottomNavigationAction
              label='Conseils'
              disableRipple={true}
              disableTouchRipple={true}
              icon={
                <img src={IconConseils} alt='Icone conseils' style={{ width: 25, height: 25 }} />
              }
            />
            <BottomNavigationAction
              label='Évolution'
              disableRipple={true}
              icon={<img src={IconSuivi} alt='Icone évolutions' style={{ width: 25, height: 25 }} />}
            />
          </BottomNavigation>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex' }}>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                },
              }}
              variant='permanent'
              anchor='left'
            >
              <List>
                {['Accueil', 'Entraînements', 'Conseils', 'Évolution'].map((text, index) => (
                  <ListItem
                    key={text}
                    onClick={() => {
                      setValue(index);
                      handleNavigation(index);
                    }}
                    disableGutters={true}
                    className={value === index ? 'active' : ''}
                  >
                    <ListItemButton
                      sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                      disableTouchRipple={true}
                    >
                      <ListItemIcon>
                        {index === 0 ? (
                          <img
                            src={IconAccueil}
                            alt='Icone accueil'
                            style={{ width: 25, height: 25 }}
                          />
                        ) : index === 1 ? (
                          <img
                            src={IconProgrammes}
                            alt='Icone Entraînements'
                            style={{ width: 25, height: 25 }}
                          />
                        ) : index === 2 ? (
                          <img
                            src={IconConseils}
                            alt='Icone conseils'
                            style={{ width: 25, height: 25 }}
                          />
                        ) : (
                          <img
                            src={IconSuivi}
                            alt='Icone évolution'
                            style={{ width: 25, height: 25 }}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={text} className={value === index ? 'active' : ''} />
                    </ListItemButton>
                  </ListItem>
                ))}
                <div className='actionsList'>
                  <div
                    className='favorisBtn'
                    onClick={() => {
                      navigate('/favoris');
                    }}
                  >
                    <div className='Icon'>
                      <FontAwesomeIcon icon='fa-regular fa-heart' />
                    </div>
                    <div className='Label'>Mes favoris</div>
                  </div>

                  <div className='border-b border-border w-full my-4' />

                  <div
                    className='profilBtn'
                    onClick={() => {
                      navigate('/profil');
                    }}
                  >
                    <div className='IconProfil'><FontAwesomeIcon icon='fa-solid fa-user' /></div>
                    <div className='Label'>Gérer mon profil</div>
                  </div>

                  <div
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    <p className='btn logout'>Se déconnecter</p>
                  </div>
                </div>
              </List>
            </Drawer>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export default Navigation;
