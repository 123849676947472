export const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const todayDate = () => {
  const today = new Date();

  const jour = String(today.getDate()).padStart(2, '0');
  const mois = String(today.getMonth() + 1).padStart(2, '0');
  const annee = today.getFullYear();

  return `${jour}/${mois}/${annee}`;
};


export function convertDateISOToJJMMAAAA(dateISO) {
  let date = new Date(dateISO);

  let jour = date.getDate();
  let mois = date.getMonth() + 1;
  let annee = date.getFullYear();
  jour = jour < 10 ? '0' + jour : jour;
  mois = mois < 10 ? '0' + mois : mois;

  return jour + '/' + mois + '/' + annee;
}
