// Import React - Redux
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './pages/Login/Login.jsx';

// import Utils
import PrivateRoutes from './utils/PrivateRoutes.jsx';
import PublicRoutes from './utils/PublicRoutes.jsx';

// import Pages
import Accueil from './pages/Accueil/Accueil.jsx';
import Programme from './pages/Programme/Programme.jsx';
import Suivi from './pages/Suivi/Suivi.jsx';
import Profil from './pages/Profil/Profil.jsx';
import Conseil from './pages/Conseil/Conseil.jsx';
import Navigation from './components//Navigation.jsx';
import ProgrammeDetails from './pages/Programme/ProgrammeDetails.jsx';
import ConseilDetails from './pages/Conseil/ConseilsDetails.jsx';
import Seance from './pages/Programme/Seance.jsx';
import Favoris from './pages/Favoris/Favoris.jsx';

function Router() {
  const isLogin = useSelector((state) => state.user.token);
  return (
    <div style={{ display: 'flex' }}>
      {isLogin && <Navigation />}
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path='/accueil' element={<Accueil />} />
          <Route path='/entrainements'>
            <Route index element={<Programme />} />
            <Route path='programme/:id' element={<ProgrammeDetails />} />
            <Route path='seance/:seanceId' element={<Seance />} />
          </Route>
          <Route path='/conseils'>
            <Route index element={<Conseil />} />
            <Route path=':id' element={<ConseilDetails />} />
          </Route>
          <Route path='/evolution' element={<Suivi />} />
          <Route path='/profil' element={<Profil />} />
          <Route path='/favoris' element={<Favoris />} />
        </Route>
        <Route element={<PublicRoutes />}>
          <Route path='/login' element={<Login />} />
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
        <Route path='/' element={<Navigate to='/login' />} />
      </Routes>
    </div>
  );
}

export default Router;
